<template>
<div>
	<v-data-table :items="items" :headers="headers">
		<template v-slot:item.user="{item}">
			<span v-if="item.user && users[item.user]">	
				{{users[item.user].displayName }}
			</span>
		</template>
		<template v-slot:item.lastLogin="{item}">
			<span v-if="item.user && users[item.user]">	
				{{ users[item.user].lastLogin ? users[item.user].lastLogin.toDate() : users[item.user].created.toDate() | moment("D.MM.YYYY") }}
			</span>
		</template>
		<template v-slot:item.status="{item}">
			{{ mwtr.CollaborationStatuses[item.status] }}
		</template>
	</v-data-table>
</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	export default {
		name: "CollaborationDashboard", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment,
				users: state => state.users,
				collaborations: state => state.collaborations
			}),
			isLead(){
				return this.$store.getters.isCurrentlyLeadRespondent
			},
			map(){
				return this.$store.getters.igMapped
			},
			headers(){
				return [
					{value: "section", text: this.mwtr.Section},
					{value: "status", text: this.mwtr.Status},
					{value: "collaborationStatus", text: this.mwtr.CollaborationStatus},
					{value: "user", text: this.mwtr.UsersNavItem},
					{value: "lastLogin", text: this.mwtr.LastLogin},
				]
			},
			items(){
				const self = this;
				var map = self.map;
				var assessment = self.assessment;
				var items = Object.keys(map).map( m => {
					var item = {}
					item.section = map[m].name;
					item.status = map[m].status;
					item.collaboration = assessment.sections[map[m].section].collaboration;
					item.collaborationStatus = self.collaborationStatus(item.collaboration);
					item.user = self.user(item.collaboration);
					return item;
				})
				return items;
				// return Object.keys(map).filter( m => map[m].status == 'delegated')
			}, 

		},
		methods: {
			collaborationStatus(id){
				var collaboration = this.collaborations[id] || {};
				var status = collaboration.status;
				if( !status ){
					return ""
				}
				return this.mwtr.CollaborationStatuses[status]
			},
			user(id){
				var collaboration = this.collaborations[id] || {};
				return collaboration.user;
			}
		}, 
		watch: {
			items: {
				immediate: true,
				handler(){
					const self = this;
					self.items.forEach( o => {
						if( o.user && !self.users[o.user] ){
							self.$store.dispatch("users/fetchOne", o.user )
						}
					})
				}
			}
		}
		// created(){
			
		// }

	}
// </script>"